<template>
  <div class="h-full">
    <loader :loading="loading" :backdrop="true" />
    <div class="form-row w-full">
      <FinalForm
        ref="form"
        @submit="saveData"
        :initialValues="initialValues"
        class="w-full pr-2"
      >
        <div class="stick bg-white pb-3 z-10">
          <div class="form-row">
            <label class="font-semibold" style="color: var(--highlightColor500)"
              >covid-19 form
            </label>
          </div>
        </div>
        <div>
          <label class="form-section-sub-title">
            NOTICE OF TEMPORARY WAIVER OF LATE FEES
          </label>
          <div class="form-row text-title">
            This notice relates to the late fees for the non-payment of rent
            incurred during the period:
          </div>
          <div class="form-row">
            <TextField name="beginingDate" label="beginning date" type="date" />
            <TextField name="endingDate" label="ending date" type="date" />
          </div>
          <div class="form-row">
            <div class="text-title">
              During the Waiver Period and provided you pay your monthly rent on
              or before the
            </div>
            <TextInputSmall name="waiverPeriodRent" type="number" />
            <div class="text-title">
              day of each month, we agree to waive the late fees
            </div>
          </div>
          <div class="form-row">
            <div class="text-title">Contact our team by calling</div>
            <TextInputSmall name="byCalling" />
            <div class="ml-2 mr-2 text-title">or by emailing</div>
            <TextField
              name="byEmailing"
              class="ml-4"
            />
          </div>
          <div class="h-4"></div>

          <label class="form-section-sub-title">PAYMENT PLAN AGREEMENT</label>
          <div class="form-row text-title">
            This Payment Plan Agreement relates to the TAA Lease Contract signed
          </div>
          <div class="form-row">
            <div class="form-row w-1/2">
              <TextField name="ttaLeaseContractInputMain" />
            </div>
            <div class="text-title ml-4">,</div>
            <TextInputSmall name="ttaLeaseContractInputSub" />
          </div>
          <div class="form-row text-title">
            We are willing to enter into an agreement with you to provide
            temporary flexibility for paying rent and other sums that come due:
          </div>
          <div class="form-row">
            <CheckboxInputSmall type="checkbox" name="duringAreement" />
            <div class="text-title">
              during the month of the Lease term during which this Agreement is
              executed;
            </div>
          </div>
          <div class="form-row">
            <CheckboxInputSmall type="checkbox" name="followingAgreement" />
            <div class="text-title">
              during the month of the Lease following the month in which this
              Agreement is executed; or
            </div>
          </div>
          <div class="form-row">
            <CheckboxInputSmall type="checkbox" name="followingPeriod" />
            <div class="text-title">for the following period of time:</div>
            <TextInputSmall name="followingPeriodInputValue" />
          </div>
          <div class="form-row text-title">
            You agree to make payments as follows:
          </div>
          <div class="form-row">
            <TextField
              name="futureDueDates"
              label="Future Due Date(s)"
            />
            <TextField name="amountDue" label="Amounts Due" type="number" />
          </div>
          <div class="form-row">
            <TextField name="futureDate1" />
            <TextField name="amountDue1" type="number" />
          </div>
          <div class="form-row">
            <TextField name="futureDate2" />
            <TextField name="amountDue2" type="number" />
          </div>
          <div class="form-row">
            <TextField name="futureDate3" />
            <TextField name="amountDue3" type="number" />
          </div>
          <div class="form-row">
            <TextField name="futureDate4" />
            <TextField name="amountDue4" type="number" />
          </div>
          <div class="form-row">
            <TextField name="futureDate5" />
            <TextField name="amountDue5" type="number" />
          </div>
          <div class="h-4"></div>

          <label class="form-section-sub-title"
            >VIRUS WARNING AND WAIVER ADDENDUM
          </label>
          <div class="form-row text-title">
            The Virus Warning and Waiver Addendum relates to the TAA Lease
            Contract Signed on:
          </div>
          <div class="form-row">
            <div class="form-row w-1/2">
              <TextField name="taaVirusLeaseContractInputMain" />
            </div>
            <div class="text-title ml-4">,</div>
            <TextInputSmall name="taaVirusLeaseContractInputSub" />
          </div>
        </div>
      </FinalForm>
      <modal-footer :footer="footer" :primary="saveData"></modal-footer>
    </div>
  </div>
</template>

<script>
import ModalNavigation from "@/mixins/ModalNavigation";
import Loader from "@/components/ui/Loader";
import TextField from "@/components/form/TextField";
import TextInputSmall from "@/components/form/TextInputSmall";
import CheckboxInputSmall from "@/components/form/CheckboxInputSmall";
import { FinalForm } from "vue-final-form";
import InitializeFormMixin from "@/components/form/InitializeFormMixin";
import ModalFooter from "@/components/ui/modals/ModalFooter";

export default {
  mixins: [ModalNavigation, InitializeFormMixin],
  components: {
    Loader,
    TextField,
    FinalForm,
    ModalFooter,
    TextInputSmall,
    CheckboxInputSmall,
  },
  data() {
    return {
      beginingDate: "",
      endingDate: "",
      waiverPeriodRent: "",
      byCalling: "",
      byEmailing: "",
      ttaLeaseContractInputMain: "",
      ttaLeaseContractInputSub: "",
      duringAreement: false,
      followingAgreement: false,
      followingPeriod: false,
      followingPeriodInputValue: "",
      futureDate1: "",
      futureDate2: "",
      futureDate3: "",
      futureDate4: "",
      futureDate5: "",
      amountDue1: "",
      amountDue2: "",
      amountDue3: "",
      amountDue4: "",
      amountDue5: "",
      taaVirusLeaseContractInputMain: "",
      taaVirusLeaseContractInputSub: "",
      footer: {
        primaryButton: "save",
      },
      loading: false,
    };
  },
  computed: {
    initialValues() {
      return {
        beginingDate: "",
        endingDate: "",
        waiverPeriodRent: "",
        byCalling: "",
        byEmailing: "",
        ttaLeaseContractInputMain: "",
        ttaLeaseContractInputSub: "",
        duringAreement: false,
        followingAgreement: false,
        followingPeriod: false,
        followingPeriodInputValue: "",
        futureDate1: "",
        futureDate2: "",
        futureDate3: "",
        futureDate4: "",
        futureDate5: "",
        amountDue1: "",
        amountDue2: "",
        amountDue3: "",
        amountDue4: "",
        amountDue5: "",
        taaVirusLeaseContractInputMain: "",
        taaVirusLeaseContractInputSub: "",
      };
    },
  },
  methods: {
    saveData() {},
  },
};
</script>

<style scoped>
input {
  accent-color: var(--highlightColor500) !important;
}
.stick {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}
button {
  outline: none;
  box-shadow: none;
}
</style>

